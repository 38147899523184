import React from "react";
import { Grid, Button, ButtonGroup, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LabelKeys } from "../../Utils/enums/labelKeys";
import { SendBy } from "../Delivery/types";
import { MessageStyles } from "./styles";
import { IUploadedFile } from "../FileUploader/types";
import { SystemPart } from "../../Utils/enums/enums";
import { StateNames, FieldNames } from "../../Utils/types";
import { theme } from "../../appTheme";
import { IsResellerAuth, IsAuth } from "../../Utils/authentication";
import { IImageOption } from "../../Modules/Products/types";

interface IMessageSendByWrapperProps {
  id: string;
  sendBy: SendBy;
  systemPart?: SystemPart;
  onStateChanged: (
    stateName: StateNames.stepperThreeState | StateNames.stepperFourState,
    value: string | number | boolean | IUploadedFile | IImageOption,
    fieldName: FieldNames
  ) => void;
  onSetResetImageSelector: (value: string) => void;
}

export default function MessageSendByWrapper(props: IMessageSendByWrapperProps) {
  const { t: translate } = useTranslation();
  const classes = MessageStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isAdmin =
    (IsResellerAuth() &&
      location.pathname.includes(
        `/${translate(LabelKeys.ResellerUrl)}/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`
      )) ||
    (IsAuth() && location.pathname.includes(`/${translate(LabelKeys.AdminUrl)}/${translate(LabelKeys.OrderUrl)}`));

  function handleFieldOnChange(
    stateName: StateNames.stepperThreeState | StateNames.stepperFourState,
    value: string | boolean | number | IUploadedFile | IImageOption,
    fieldName: FieldNames
  ) {
    props.onStateChanged(stateName, value, fieldName);
  }

  function handleSendBySmsClicked(): void {
    handleFieldOnChange(StateNames.stepperThreeState, false, FieldNames.customMessage);
    handleFieldOnChange(StateNames.stepperThreeState, SendBy.SMS, FieldNames.sendBy);
    handleFieldOnChange(StateNames.stepperFourState, "", FieldNames.receiverEmails);
    handleFieldOnChange(StateNames.stepperFourState, 0, FieldNames.amountOfMailings);
    handleFieldOnChange(StateNames.stepperFourState, false, FieldNames.sendToSameReceiver);
    handleFieldOnChange(StateNames.stepperThreeState, "", FieldNames.messageSignature);
    handleFieldOnChange(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.uploadedImage);
    props.onSetResetImageSelector("sendBySMSBtn");
  }

  function handleSendByEmailClicked(): void {
    handleFieldOnChange(StateNames.stepperThreeState, false, FieldNames.customMessage);
    handleFieldOnChange(StateNames.stepperThreeState, SendBy.Email, FieldNames.sendBy);
    handleFieldOnChange(StateNames.stepperFourState, "", FieldNames.receiverPhoneNumbers);
    handleFieldOnChange(StateNames.stepperFourState, "", FieldNames.receiverEmails);
    handleFieldOnChange(StateNames.stepperFourState, 0, FieldNames.amountOfMailings);
    handleFieldOnChange(StateNames.stepperFourState, false, FieldNames.sendToSameReceiver);
    handleFieldOnChange(StateNames.stepperThreeState, "", FieldNames.messageSignature);
    handleFieldOnChange(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.uploadedImage);
    props.onSetResetImageSelector("sendByEmailBtn");
  }

  function handleSendByEmailPdfClicked(): void {
    handleFieldOnChange(StateNames.stepperThreeState, true, FieldNames.customMessage);
    handleFieldOnChange(StateNames.stepperThreeState, SendBy.EmailPDF, FieldNames.sendBy);
    handleFieldOnChange(StateNames.stepperFourState, "", FieldNames.receiverPhoneNumbers);
    handleFieldOnChange(StateNames.stepperFourState, "", FieldNames.receiverEmails);
    handleFieldOnChange(StateNames.stepperFourState, 0, FieldNames.amountOfMailings);
    handleFieldOnChange(StateNames.stepperFourState, false, FieldNames.sendToSameReceiver);
    handleFieldOnChange(StateNames.stepperThreeState, "", FieldNames.messageSignature);
    handleFieldOnChange(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.uploadedImage);
    props.onSetResetImageSelector("sendByEmailPDFBtn");
  }

  function handleSendByPhysicalCardClicked(): void {
    handleFieldOnChange(StateNames.stepperThreeState, true, FieldNames.customMessage);
    handleFieldOnChange(StateNames.stepperThreeState, SendBy.PhysicalCard, FieldNames.sendBy);
    handleFieldOnChange(StateNames.stepperFourState, "", FieldNames.receiverPhoneNumbers);
    handleFieldOnChange(StateNames.stepperFourState, "", FieldNames.receiverEmails);
    handleFieldOnChange(StateNames.stepperFourState, 0, FieldNames.amountOfMailings);
    handleFieldOnChange(StateNames.stepperFourState, false, FieldNames.sendToSameReceiver);
    handleFieldOnChange(StateNames.stepperThreeState, "", FieldNames.messageSignature);
    handleFieldOnChange(StateNames.stepperThreeState, {} as IUploadedFile, FieldNames.uploadedImage);
    handleFieldOnChange(StateNames.stepperThreeState, {} as IImageOption, FieldNames.selectedImage);
    props.onSetResetImageSelector("sendByPhysicalCardBtn");
  }

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 40, float: "left" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "auto" }}>
        <ButtonGroup
          variant="outlined"
          orientation={"horizontal"}
          style={{ float: "left", display: "flex", flexDirection: "row", width: "100%" }}
        >
          <Button
            id="sendBySMSBtn"
            startIcon={
              !isMobile ? (
                <svg width="20" height="20" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
              ) : (
                ""
              )
            }
            style={{
              borderColor: "#000",
              height: isMobile ? 74 : 44,
              width: "100%",
              borderRadius: 0,
              color: props.sendBy == SendBy.SMS ? "#fff" : "#000",
              backgroundColor: props.sendBy == SendBy.SMS ? "#000" : "#fff",
            }}
            className={props.sendBy == SendBy.SMS ? classes.selectedDeliveryOption : classes.notSelectedDeliveryOption}
            onClick={() => (props.sendBy != SendBy.SMS ? handleSendBySmsClicked() : "")}
          >
            <div>
              {isMobile && (
                <div>
                  <svg width="20" height="20" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>
                </div>
              )}
              <div>{translate(LabelKeys.SMS)}</div>
            </div>
          </Button>
          <Button
            id="sendByEmailBtn"
            startIcon={
              !isMobile ? (
                <svg width="20" height="20" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
              ) : (
                ""
              )
            }
            style={{
              borderColor: "#000",
              height: isMobile ? 74 : 44,
              width: "100%",
              borderRadius: 0,
              textTransform: "unset",
              color: props.sendBy == SendBy.Email ? "#fff" : "#000",
              backgroundColor: props.sendBy == SendBy.Email ? "#000" : "#fff",
            }}
            className={props.sendBy == SendBy.Email ? classes.selectedDeliveryOption : classes.notSelectedDeliveryOption}
            onClick={() => (props.sendBy != SendBy.Email ? handleSendByEmailClicked() : "")}
          >
            <div>
              {isMobile && (
                <div>
                  <svg width="20" height="20" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                  </svg>
                </div>
              )}
              <div>{translate(LabelKeys.Email)}</div>
            </div>
          </Button>
          {(props.systemPart != SystemPart.Private || isAdmin) && !isMobile && (
            <Button
              id="sendByEmailPDFBtn"
              startIcon={
                !isMobile ? (
                  <svg width="20" height="20" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                    <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                  </svg>
                ) : (
                  ""
                )
              }
              style={{
                borderColor: "black",
                height: isMobile ? 74 : 44,
                width: "100%",
                borderRadius: 0,
                textTransform: "unset",
                color: props.sendBy == SendBy.EmailPDF ? "#fff" : "#000",
                backgroundColor: props.sendBy == SendBy.EmailPDF ? "#000" : "#fff",
              }}
              className={props.sendBy == SendBy.EmailPDF ? classes.selectedDeliveryOption : classes.notSelectedDeliveryOption}
              onClick={() => (props.sendBy != SendBy.EmailPDF ? handleSendByEmailPdfClicked() : "")}
            >
              <div>
                {isMobile && (
                  <div>
                    <svg width="20" height="20" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                      <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                    </svg>
                  </div>
                )}
                <div>{translate(LabelKeys.PDF)}</div>
              </div>
            </Button>
          )}
          {/* {(props.systemPart != SystemPart.Private || isAdmin) && !isMobile && !isSm && (
            <Button
              id="sendByPhysicalCardBtn"
              startIcon={
                <svg width="20" height="20" fill="currentColor" className="bi bi-postcard" viewBox="0 0 16 16">
                  <path
                    fillRule="evenodd"
                    d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4Zm7.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1H2.5a.5.5 0 0 1-.5-.5ZM10.5 5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3ZM13 8h-2V6h2v2Z"
                  />
                </svg>
              }
              style={{
                borderColor: "black",
                height: isMobile ? 74 : 44,
                width: "100%",
                borderRadius: 0,
                textTransform: "unset",
                color: props.sendBy == SendBy.PhysicalCard ? "#fff" : "#000",
                backgroundColor: props.sendBy == SendBy.PhysicalCard ? "#000" : "#fff",
              }}
              className={props.sendBy == SendBy.PhysicalCard ? classes.selectedDeliveryOption : classes.notSelectedDeliveryOption}
              onClick={() => handleSendByPhysicalCardClicked()}
            >
              {translate(LabelKeys.PhysicalCard)}
            </Button>
          )} */}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
